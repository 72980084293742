import barba from '@barba/core';
import gsap from 'gsap';

const BARBA_STATUS_BEFORE       = 'before';
const BARBA_STATUS_BEFORE_LEAVE = 'beforeLeave';
const BARBA_STATUS_LEAVE        = 'leave';
const BARBA_STATUS_AFTER_LEAVE  = 'afterLeave';
const BARBA_STATUS_BEFORE_ENTER = 'beforeEnter';
const BARBA_STATUS_ENTER        = 'enter';
const BARBA_STATUS_AFTER_ENTER  = 'afterEnter';
const BARBA_STATUS_AFTER        = 'after';

const COL_DURATION = 0.2;
const EASING_IN = 'power1.easeOut';
const EASING_OUT = 'power1.easeIn';
const STAGGER_DELAY = 0.06;

const TEXT_DURATION = 2.13;
const TEXT_EASING_IN = "slow(0.1, 0.7, false)";

if (history.scrollRestoration) {
    history.scrollRestoration = 'manual';
}

document.addEventListener('DOMContentLoaded', function () {

    // @NOTE
    // For more information about configuration / setup and possibilities, see https://barba.js.org/docs/getstarted/intro/
    barba.init({
        debug: false,
        logLevel: 'error',
        cacheIgnore: true,
        prefetchIgnore: true,
        preventRunning: true,
        timeout: 15000,
        prevent: ({el}) => el.classList && el.classList.contains('barba-prevent'),
        requestError: (trigger, action, url, response) => {
            // go to a custom 404 page if the user click on a link that return a 404 response status
            // if (action === 'click' && response.status && response.status === 404) {
            //     return true;
            // }

            // @NOTE
            // Prevent Barba from redirecting the user to the requested URL
            // This is equivalent to e.preventDefault() in this context
            return false;
        },
        transitions: [
            {
                name: 'simple',
                sync: true,
                // @NOTE
                // The "to" key is to set a rule to know when this animation should run or not based on the next page namespace.
                to: {
                    namespace: [
                        'simple'
                    ]
                },
                leave(data) {

                    //move top previous page
                    return gsap.to(data.current.container, {
                        y: '-20vh',
                        duration: 1.3,
                        ease: "power3.inOut",
                        onComplete: function(){
                            return true;
                        }
                    });
                },
                enter(data) {
                    data.next.container.classList.add('transitionning');
                    gsap.set('.transitionning .fade-in', { opacity: 0 });

                    // Reinject Blitz script for Ajax injection
                    if (injectElements instanceof Function) {
                        injectElements();
                    }
                    else {
                        const blitzScript = Array.from(document.querySelectorAll('script')).filter(i =>
                            i.innerText && i.innerText.indexOf('blitz') !== -1
                        );
                        Array.from(blitzScript).forEach(script => {
                            let newScript = document.createElement("script");
                            let inlineScript = document.createTextNode(script.textContent);
                            newScript.appendChild(inlineScript);
                            script.parentNode.replaceChild(newScript, script);
                        });
                    }

                    //make animated svg re-render embedded js so they are executed. Barba append next page to DOM does not seems to trigger inline JS so we have to append them again and then the page will see them and execute them.
                    Array.from(data.next.container.getElementsByTagName('svg')).forEach(svg => {
                        Array.from(svg.getElementsByTagName("script")).forEach(script => {
                            let newScript = document.createElement("script");
                            let inlineScript = document.createTextNode(script.textContent);
                            newScript.appendChild(inlineScript);
                            script.parentNode.replaceChild(newScript, script);
                        })
                    });

                    Array.from(data.next.container.querySelectorAll('[id^="script_enkoder"]')).forEach(enkoder => {
                        let newScript = document.createElement("script");
                        let inlineScript = document.createTextNode(enkoder.textContent);
                        newScript.appendChild(inlineScript);
                        enkoder.parentNode.replaceChild(newScript, enkoder);
                    });

                    return animation()

                    function animation(){
                        //move top next page
                        gsap.from(data.next.container, {
                            y: '100vh',
                            duration: 1.3,
                            ease: "power3.inOut",
                            onComplete: function(){
                                data.next.container.classList.remove('transitionning');
                                data.next.container.style = '';
                            }
                        });
                    }
                }
            }
        ]
    });


    //for dev purpose only, prevent yii admin bar to make redirect call with barba js.
    //timeout is needed because the debug toolbar is added after our script here.
    setTimeout(function(){
        document.querySelectorAll("#yii-debug-toolbar a").forEach(item=>item.setAttribute('data-barba-prevent','self'));
    }, 1500);

    barba.hooks.before((data)=>{
        document.querySelector('html').classList.add('in-transition');
        document.querySelector('header').classList.remove('nav-opened');
    });

    barba.hooks.afterLeave((data) => {
        if (window.lenis) {
            window.lenis.start();
            window.lenis.scrollTo(0, {
                immediate: true,
                lock: true
            });
        }
        else {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'instant',
            });
        }

        // @NOTE
        // Get DOM of next and current page to compare the list of script found in the page to add or to remove them.
        const currentDOM = new DOMParser().parseFromString(data.current.html, 'text/html');
        const nextDOM = new DOMParser().parseFromString(data.next.html, 'text/html');
        const currentArrayScript = Array.from(currentDOM.querySelectorAll('body script'));
        const nextArrayScript = Array.from(nextDOM.querySelectorAll('body script'));

        //@NOTE
        // Parse all scripts from the next page to detect if they doesn't exit in the current page to add them
        let scriptNodeToAdd = [];
        let scriptNodeToRemove = [];
        let scriptRemoved = 0;
        let scriptLoaded = 0;

        nextArrayScript.forEach((nScript, nIndex) => {
            if (nScript.attributes && nScript.attributes.src) {
                let nScriptDom = document.querySelector(`script[src="${nScript.attributes.src.value}"]`)

                if (!nScriptDom) {
                    // Append script
                    let scriptNode = document.createElement('script');
                    scriptNode.async = nScript.attributes.async;
                    scriptNode.src = nScript.attributes.src.value;
                    scriptNodeToAdd.push(scriptNode)
                }
            }
        });

        //@NOTE Parse all scripts from the current page to detect if they doesn't exit in the current page to remove them
        currentArrayScript.forEach((cScript, nIndex) => {
            if (cScript.attributes && cScript.attributes.src) {
                let cSprintDom = nextDOM.querySelector(`script[src="${cScript.attributes.src.value}"]`);

                if (!cSprintDom) {
                    // Delete script
                    scriptNodeToRemove.push(document.querySelector(`script[src="${cScript.attributes.src.value}"]`));
                }
            }
        });

        // @NOTE
        // Create two promise for add and remove to be sure all scripts will be loaded or removed and then we can go to the next state of barba
        let addScripts = new Promise((resolve, reject) => {
            if (scriptNodeToAdd.length > 0) {
                scriptNodeToAdd.forEach((node, index) => {
                    document.querySelector('body').appendChild(node);
                    node.addEventListener('load', () => {
                        scriptLoaded++;

                        if (scriptLoaded === scriptNodeToAdd.length) {
                            resolve();
                        }
                    })
                });
            } else {
                resolve();
            }
        });

        let removeScripts = new Promise((resolve, reject) => {
            if (scriptNodeToRemove.length > 0) {
                scriptNodeToRemove.forEach((node, index) => {
                    try {
                        document.querySelector('body').removeChild(node);
                        scriptRemoved++;
                        if (scriptRemoved === scriptNodeToRemove.length) {
                            resolve();
                        }
                    } catch (error) {
                        // NotFoundError
                        resolve();
                    }
                });
            } else {
                resolve();
            }
        });

        data.current.container.remove();

        // Return Promise since Barba works with promises. Won't go to the next state until all promises are done.
        return Promise.all([addScripts, removeScripts]);
    });

    barba.hooks.after((data) => {
        // @NOTE
        // Dispatch new page is now the current page
        // We init again all manage me views at the same time to restart and clear all events
        window.dispatchEvent(new CustomEvent("pageTransition", {
            detail: {
                status: BARBA_STATUS_AFTER
            }
        }));

        setTimeout(function(){
            //Me.skin.initFields();
            document.querySelector('html').classList.remove('in-transition');
            Me.manage.initViews();
            Me.formManager.initForms();
            window.dispatchEvent(new Event('resize'));
        },0);

        document.querySelector('html').classList.remove('no-scroll');

        //make sure to go to anchors on pageload
        if(location.hash.slice(1)){
            window.lenis.scrollTo(location.hash,{
                offset: 0,
                duration: 1,
                easing: function easeInOutSine(x) {
                    return -(Math.cos(Math.PI * x) - 1) / 2;
                }
            });
        }
    });
});
